@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

::selection {
  background: rgba(0, 0, 0, 0.2);
}

html {
  scroll-behavior: smooth;
}

/* body {
  background: #f9f9f9;
  max-height: 100%;
  overflow-x: hidden;
}

header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 100px;
  display: flex;
  align-items: center;
  z-index: 100;
  background: transparent;
} */

/* Update your existing header styles */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}

/* Add padding to body to account for fixed header */
body {
  background: #f9f9f9;
  padding-top: 5rem;
  overflow-x: hidden;
}
@supports (backdrop-filter: blur(12px)) {
  .backdrop-blur-sm {
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }
}

.logo {
  width: 120px;
  height: auto;

  /* Add more styling as desired */
}

.parallax {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#text {
  position: absolute;
  font-size: 7rem;
  color: #fff;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.parallax img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.sec {
  position: relative;
  background: #003329;
  padding: 100px;
  width: 100%;
  text-align: center;
}

.sec h2 {
  font-size: 3em;
  color: #fff;
  margin-bottom: 10px;
}

.sec p {
  font-size: 1em;
  color: #fff;
  font-weight: 300;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-shake {
  animation: shake 0.3s ease-in-out;
}

/* Mobile-specific styles */
@media (max-width: 840px) {
  /* Header adjustments */
  header {
    display: block;
  }

  .logo {
    width: 40%;
    /* height: 50%; */
  }

  /* Parallax section adjustments */
  .parallax {
    height: 30vh;
    overflow: hidden;
    position: relative;
  }

  /* Specific positioning for each image on mobile */
  #leaf {
    max-width: 100%;
    min-height: 90%;
    left: 0%;
    top: 0%; /* Adjust this to bring it vertically into the view */
    transform: translate(-0%, -0%); /* Centering and adjusting position */
    opacity: 1; /* Optional: make it semi-transparent if it's covering too much */
  }

  #hill1 {
    max-width: 100%;
    min-height: 122%;
    left: 0;
    top: 0;
  }

  #hill2 {
    max-width: 100%;
    min-height: 122%;
    left: 0%;
    top: 0;
  }

  #hill3 {
    max-width: 100%;
    min-height: 122%;
    left: 0%;
    top: 0;
  }

  #hill4 {
    max-width: 100%;
    min-height: 122%;
    left: 0%; /* Adjust to bring hill4 more into the screen */
    top: 0;
  }

  #hill5 {
    max-width: 100%;
    min-height: 122%;
    left: 0%; /* Adjust to push hill5 further outside the screen */
    top: 0;
  }

  #plant {
    max-width: 100%;
    min-height: 122%;
    left: 0%;
    top: 0;
  }

  #tree {
    max-width: 100%;
    min-height: 122%;
    left: 0; /* Adjust to bring more of the left part of tree into view */
    top: 0;
  }

  /* Main title adjustments */
  #text {
    font-size: 2.9rem;
    text-align: center;
    margin-top: 0;
  }

  /* Content section adjustments */
  .sec {
    padding: 20px;
  }

  .sec h2 {
    font-size: 1.4rem;
  }

  .sec p {
    font-size: 0.8rem;
  }
}
